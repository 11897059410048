<template>
  <div class="menber-dialog">
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <!-- <el-form ref="form" label-width="120px">
        <el-form-item label="项目详情:">{{
          currentItem.companyCode
        }}</el-form-item>
      </el-form> -->
      <el-form  label-width="160px">
        <el-form-item label="名称:" >
          {{ currentItem.companyName }}
        </el-form-item>
        <el-form-item label="意向区域:">
          {{ currentItem.intentionRegion }}
        </el-form-item>
        <el-form-item label="托管类型:">
          {{ currentItem.serviceType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="可托管土地面积:">
          {{ currentItem.serviceArea }}
        </el-form-item>
        <el-form-item label="已托管土地面积:">
          {{ currentItem.serviceAreaOlde }}
        </el-form-item>
        <el-form-item label="托管种养品种:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="其他说明:">
          {{ currentItem.remark }}
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
      </el-form>
      <div class="btns"><el-button size="small" type="info" @click="back">关闭</el-button><el-button size="small" type="primary" @click="tuisong">推送</el-button></div>
    </el-dialog>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: true,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
    };
  },
  mounted() {},
  computed: mapGetters(["userInfo"]),
  watch: {},
  methods: {
    tuisong(){
      this.$parent.push(this.currentItem);
    },
    // 构造回显的现场照片路径
    init(obj) {
      let xczp = [];
      if (obj.photos) {
        JSON.parse(obj.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      obj.xczp = xczp;
      this.currentItem = obj;
    },
    back() {
      this.$emit("closeDialog2", false);
    },
    handleClose() {
      this.$emit("closeDialog2", false);
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
.btns{
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}
</style>
  